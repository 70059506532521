









import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AccountConnected: () => import(/* webpackChunkName: "v-d-con" */'@/components/dashboard/AccountConnected.vue'),
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
    }),
    account(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.business.id);
    },
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
  watch: {
    'business.id': {
      immediate: true,
      handler(to) {
        if (to) this.fetchBalances();
      },
    },
  },
  methods: {
    async fetchBalances() {
      await this.$store.dispatch('business/GetBalances', {
        BUSINESS_ID: this.business.id,
        query: {
          cached: 'yes',
        },
      }).catch((e) => void e);
    },
  },
});
